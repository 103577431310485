import axios from '@/utils/axios';
const get = async () => {
    let result = [];
    await axios.get('usuarios/get').then((data) => {
        result = data.data;
    }).catch((err) => {
        console.log('erro ao buscar usuarios', err);
    });

    return result;
}

const store = async (data) => {
    // console.log('data recebida no store', data)
    let result
    result = await axios.post('usuarios/store', data)
    // console.log(result)
    return result
}

const setToken = async (data) => {
    // console.log('data recebida no store', data)
    let result
    result = await axios.post('usuarios/setToken', data)
    // console.log(result)
    return result
}

const del = async (user) => {
    // console.log('usuario no del', user)
    let result = await axios.post('usuarios/delete', user)
    return result.data
}


export default {
    get,
    store,
    del, setToken
}