<template>
  <div class="container-fluid">
      <BarraCrud :botaoNovo="true" :onFiltrar="filtrar" :onNovo="onNovo" />
      <UsuariosTable ref="usuariosTableComp" :filtro="filtro" />
  </div>
</template>

<script>
import UsuariosTable from '@/components/Usuarios/UsuariosTable'
import BarraCrud from '@/components/common/BarraCrud'
export default {
    components:{
        BarraCrud,
        UsuariosTable
    },
    data(){
        return{
            filtro:''
        }
    },
    methods:{
        onNovo(){
            this.$refs.usuariosTableComp.novo()
        },
        filtrar(filtro){
            this.filtro = filtro
        }
    }
};
</script>
