<template>
  <div class="container-fluid">
    <b-form @submit.stop.prevent="onSubmit()">
      <div class="row">
        <div class="col-9">
          <b-form-group>
            <label for="nome">Nome</label>
            <b-form-input
              id="nome"
              placeholder="Nome"
              v-model="$v.usuario.nome.$model"
              :state="validateState('nome')"
              aria-describedby="nome-invalido"
            />

            <b-form-invalid-feedback id="nome-invalido">
              Esse campo é um obrigatório!.
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-12 col-sm-3">
          <b-form-group>
            <label>Situação</label>

            <b-form-checkbox
              v-model="usuario.situacao"
              value="1"
              unchecked-value="0"
              name="check-button"
              switch
              class="mt-2"
            >
              <span v-if="!usuario.situacao || usuario.situacao == 0"
                >Inativo</span
              >
              <span v-if="usuario.situacao && usuario.situacao == 1"
                >Ativo</span
              >
            </b-form-checkbox>

            <b-form-invalid-feedback id="input-2-live-feedback">
              This is a required field.
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <b-form-group>
            <label>Usuário</label>
            <b-input
              placeholder="Usuário"
              v-model="$v.usuario.usuario.$model"
              :state="validateState('usuario')"
            />
            <b-form-invalid-feedback>
              Informe um nome de usuário válido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-12 col-sm-6">
          <b-form-group>
            <label>Senha</label>
            <b-input
              placeholder="Senha"
              type="password"
              :state="validateState('senha')"
              v-model="$v.usuario.senha.$model"
            />
            <b-form-invalid-feedback>
              Informe uma senha válida
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <SelectEmpresaCaixa
            :empresa="usuario.cod_empresa"
            :caixa="usuario.cod_caixa"
            @empresa="usuario.cod_empresa = $event"
            @caixa="usuario.cod_caixa = $event"
          />
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <b-form-group>
            <label>Grupo</label>
            <b-form-select
              :options="grupos"
              :state="validateState('tipo')"
              v-model="$v.usuario.tipo.$model"
            />
            <b-form-invalid-feedback>
              Grupo do usuario é obrigatório
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="Empresas Gerencia"
            v-if="usuario && usuario.configuracoes && usuario.tipo == 'Gerente'"
          >
            <v-select
              style="max-width: 500px; display: block"
              multiple
              v-model="usuario.configuracoes.empresas"
              :options="empresas"
              :reduce="(emp) => emp.cod_empresa"
              label="nome"
            />
          </b-form-group>

          <div>
            <b-checkbox switch v-model="usuario.super" :value="1"
              >SUPER</b-checkbox
            >
          </div>
          <div>
            <b-checkbox switch v-model="usuario.ifood" :value="1"
              >IFood</b-checkbox
            >
          </div>
        </div>
        <div class="col-12 col-sm-6 card card-body">
          <b-form-group>
            <label><b-icon-lock /> Autorizar Solicitações</label>
            <b-select
              v-model="usuario.configuracoes.autorizador.tipo"
              :options="[
                { value: 0, text: 'Não' },
                { value: 1, text: 'Todas Empresas' },
                { value: 2, text: 'Empresas Específicas' },
              ]"
            />
            <b-form-invalid-feedback>
              Informe uma senha válida
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label="Empresas"
            v-if="
              usuario &&
              usuario.configuracoes &&
              usuario.configuracoes.autorizador &&
              usuario.configuracoes.autorizador.tipo &&
              usuario.configuracoes.autorizador.tipo === 2
            "
          >
            <v-select
              style="max-width: 500px; display: block"
              multiple
              v-model="usuario.configuracoes.autorizador.empresas"
              :options="empresas"
              :reduce="(emp) => emp.cod_empresa"
              label="nome"
            />
          </b-form-group>
        </div>
        <div class="col-12 col-sm-6">
          <b-form-group v-if="usuario.tipo == 'Moto'">
            <label>Telefone</label>
            <b-form-input
              placeholder="Telefone"
              v-mask="'(##)####-#####'"
              :state="validateState('telefone')"
              v-model="$v.usuario.telefone.$model"
            />
            <b-form-invalid-feedback>
              Telefone é obrigatório!
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="bottom-actions">
        <hr />
        <b-button
          type="submit"
          :disabled="loading"
          class="mr-3"
          variant="primary"
        >
          <i class="fas fa-save" v-if="!loading" />
          <b-spinner small v-if="loading" /> Salvar
        </b-button>
        <b-button @click="cancelar()" :disabled="loading" variant="danger">
          <i class="fas fa-ban" /><b-spinner small v-if="loading" /> Cancelar
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import UsuariosLib from "@/libs/UsuariosLib";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import SelectEmpresaCaixa from "@/components/common/SelectEmpresaCaixa.vue";

export default {
  mixins: [validationMixin],
  components: {
    SelectEmpresaCaixa,
  },
  props: {
    usuarioEdit: { type: Object, required: false },
  },
  data() {
    return {
      usuario: {
        nome: "",
        situacao: 1,
        usuario: "",
        senha: "",
        cod_empresa: null,
        tipo: null,
        cod_caixa: null,
        telefone: "",
        configuracoes: {
          autorizador: {
            tipo: 0,
            empresas: [],
          },
          empresas: [],
        },
        super: 0,
        ifood: 0,
      },
      loading: false,
      empresasDisponiveis: [],
      caixasDisponiveis: [],
      empresas: [
        this.$store.state.empresas,
        ...this.$store.state.empresas.empresas,
      ],
      grupos: [
        "Administrador",
        "Gerente",
        "Gerente Caixa",
        "Caixa",
        "Gerente Moto",
        "Moto",
        "Garçom",
        "Caixa Mesas",
        "Estoque",
        "Contador",
      ],
    };
  },
  validations: {
    usuario: {
      nome: {
        required,
      },
      usuario: {
        required,
      },
      senha: {
        required() {
          // console.log(this.usuario)
          if (!this.usuarioEdit.cod_usuario) {
            return !this.usuario.senha.length == 0;
          } else {
            return true;
          }
        },
      },
      cod_empresa: {
        required,
      },
      tipo: {
        required,
        enumGroups(value) {
          // console.log(value)
          return this.grupos.includes(value);
        },
      },
      cod_caixa: {
        required,
      },
      telefone: {
        required() {
          if (this.usuario.tipo == "Moto") {
            return !this.usuario.telefone.length == 0;
          } else {
            return true;
          }
        },
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.usuario[name];
      return $dirty ? !$error : null;
    },

    async onSubmit() {
      this.$v.usuario.$touch();
      if (this.$v.usuario.$anyError) {
        return;
      }

      try {
        this.loading = true;
        console.log("senha ao salvar", this.usuario);
        const result = await UsuariosLib.store(this.usuario);
        // console.log("resultado aqui", result);
        if (result && result.data.success) {
          this.$swal.fire({
            toast: true,
            title: "Concluído",
            text: "Usuário salvo com sucesso!",
            icon: "success",
            position: "top-end",
            timer: 1500,
            showConfirmButton: false,
            timerProgressBar: true,
          });
          this.$emit("salvou"), this.cancelar();
        } else {
          this.$swal.fire({
            toast: true,
            title: "Ops! Erro ao salvar! " + result.data.message,
            icon: "error",
            position: "top-end",
            timer: 1500,
            showConfirmButton: false,
            timerProgressBar: true,
          });
        }
      } catch (error) {
        this.$swal.fire({
          toast: true,
          title: "Ops! Erro ao salvar! " + error,
          icon: "error",
          position: "top-end",
          timer: 1500,
          showConfirmButton: false,
          timerProgressBar: true,
        });
      } finally {
        this.loading = false;
      }
    },
    limpar() {
      (this.usuario.nome = ""),
        (this.usuario.situacao = 0),
        (this.usuario.usuario = ""),
        (this.usuario.senha = ""),
        (this.usuario.cod_empresa = null),
        (this.usuario.cod_caixa = null),
        (this.usuario.tipo = "");

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    cancelar() {
      this.$bvModal.hide("modal-usuarios");
    },
    carregaEmpresas() {
      let empresas = [];
      empresas = [...this.$store.state.empresas.empresas];
      empresas.push(this.$store.state.empresas);
      empresas = empresas.sort((a, b) => {
        return a.cod_empresa - b.cod_empresa;
      });

      this.empresasDisponiveis = empresas.map((empresa) => {
        return { text: empresa.nome, value: empresa.cod_empresa };
      });
    },
    carregaCaixas() {
      let caixas = [...this.$store.state.caixas];
      this.caixasDisponiveis = caixas.map((caixa) => {
        return { text: caixa.nome, value: caixa.cod_caixa };
      });
    },
  },
  mounted() {
    this.carregaEmpresas();
    this.carregaCaixas();

    if (this.usuarioEdit) {
      if (this.usuarioEdit.cod_usuario) {
        // console.log("usuario edit", this.usuarioEdit);
        if (
          !this.usuarioEdit ||
          !this.usuarioEdit.configuracoes ||
          !this.usuarioEdit.configuracoes.autorizador
        ) {
          this.usuarioEdit.configuracoes = {
            autorizador: {
              tipo: 0,
              empresas: [],
            },
          };
        }
        Object.assign(this.usuario, this.usuarioEdit);
      }
    }
  },
};
</script>
<style></style>
